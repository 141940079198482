import { ActionFunctionArgs, json, LoaderFunctionArgs } from "@remix-run/node";
import { Form, Link, useActionData, useLoaderData } from "@remix-run/react";
import { Alert } from "~/components/Alert";
import { Button } from "~/components/Button";
import { Input } from "~/components/Input";
import { H1, P } from "~/components/Text";
import { BASE_URL } from "~/constants/api";
import { getSession } from "~/sessions";
import { login } from "~/utils/authenticate";

export async function action({ request }: ActionFunctionArgs) {
  const body = new URLSearchParams(await request.text());
  const email = body.get("email");
  if (!email) {
    return json({
      success: false,
      error: "Email is required.",
    });
  }

  try {
    const response = await fetch(BASE_URL + "/login", {
      method: "POST",
      body: JSON.stringify({ email }),
    });
    if (!response.ok) {
      return json({
        success: false,
        error: "Failed to send email.",
      });
    }
  } catch (error) {
    return json({
      success: false,
      error: "Failed to send email.",
    });
  }

  return json({
    success: true,
    error: null,
  });
}

export async function loader({ request }: LoaderFunctionArgs) {
  await login(request, "/");
  const session = await getSession(request.headers.get("Cookie"));
  const error = session.get("error");
  return {
    error,
  };
}

export default function Login() {
  const loaderData = useLoaderData<typeof loader>();
  const actionData = useActionData<typeof action>();

  return (
    <>
      <div className="flex max-w-md justify-center mx-auto flex-col">
        <H1 center>Login</H1>
        {actionData?.success ? (
          <div className="my-8">
            <Alert type="success" message="Check your email to login." />
          </div>
        ) : (
          <>
            <P>Enter your email and we'll send you a link to sign in.</P>
            <Form
              method="post"
              className="flex flex-row gap-4 w-full flex-grow"
            >
              <Input
                type="email"
                id="email"
                name="email"
                placeholder="Email"
                className="grow text-left"
              />
              <Button
                variant="outlined"
                size="lg"
                type="submit"
                className="mt-auto"
              >
                Login
              </Button>
            </Form>
          </>
        )}
        {actionData?.error && (
          <div className="my-8">
            <Alert type="error" message={actionData.error} />
          </div>
        )}
        {loaderData?.error && (
          <div className="my-8">
            <Alert
              type="error"
              message="Couldn't log you in. Please try again."
            />
          </div>
        )}
      </div>
      <div className="flex max-w-md mx-auto flex-col gap-4">
        <P className="text-md mt-4 lg:text-md" center>
          Don't have an account?{" "}
          <i>
            <Link to="/signup" className="text-lime-400">
              Sign up here
            </Link>
          </i>
        </P>
      </div>
    </>
  );
}
